<template>
    <breadcrumb btnText="返回列表" btnIcon="n-direction-left" @btn-click="goBack"></breadcrumb>
    <div class="page-user-create">
        <user-form title="新建用户"></user-form>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import Breadcrumb from '@/components/layout/Breadcrumb.vue';
import UserForm from '@/components/forms/UserForm.vue';

@Options({
    components: {
        Breadcrumb, UserForm
    },
    methods: {
        goBack(){
            this.$router.go(-1);
        }
    }
})

export default class UserCreate extends Vue {}
</script>